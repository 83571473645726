import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import "./ProductSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Form from "react-bootstrap/Form";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCartPlus,
  faLock,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBox,
  faHandHoldingHeart,
  faTruck,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import { Context } from "../../../utils/context";
import {
  calculateFutureDate,
  product_coupon,
  product_type,
  UserType,
} from "../../../utils/common";
import {
  getCheckPincodeAvailable,
  postCheckPincodeAvailable,
} from "../../../utils/apis/Product/Product";
import classNames from "classnames";
import {
  AddToCart,
  BuyNow,
  updateCartCount,
} from "../../../utils/apis/addToCart/addToCart";
import Warning_Modal from "../../CommonComponent/Warning_Modal/Warning_Modal";
import {
  addWishlist,
  wishlistContext,
} from "../../../utils/apis/Wishlist/Wishlist";

// import {
//   Magnifier,
//   GlassMagnifier,
//   SideBySideMagnifier,
//   PictureInPictureMagnifier,
//   MOUSE_ACTIVATION,
//   TOUCH_ACTIVATION,
// } from "react-image-magnifiers";

import ReactImageMagnify from "react-image-magnify";

const ProductSlider = ({
  data,
  register,
  errors,
  setError,
  clearErrors,
  getValues,
  singleVariant,
  MultipleVariant,
  onChangeVariant,
  images,
  setImages,
  setSelectedImage,
  selectedImage,
  setValue,
  reset,
  average_rating,
  CalculateMOQ,
  customerRatingrData,
  setCustomerRatingData,
  ...props
}) => {
  // Category Types
  const [selectedOption, setSelectedOption] = useState("Transparent Tape");

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  const options = [
    { key: "Transparent Tape", label: "Transparent Tape" },
    { key: "Brown Tape", label: "Brown Tape" },
  ];

  const visibleOptions = options.filter(
    (option) => option.key !== selectedOption
  );

  // const colorsd = ["#000", "#33FF57", "#3357FF", "#F333FF"];

  // const slidesData = [
  //   {
  //     image: "/assests/ProductDetails/p1.png",
  //     onClick: () =>
  //       handleImageClick(
  //         process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"
  //       ),
  //   },
  //   {
  //     image: "/assests/ProductDetails/p1.png",
  //     onClick: () =>
  //       handleImageClick(
  //         process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"
  //       ),
  //   },
  //   {
  //     image: "/assests/ProductDetails/p1.png",
  //     onClick: () =>
  //       handleImageClick(
  //         process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"
  //       ),
  //   },
  //   {
  //     image: "/assests/ProductDetails/p1.png",
  //     onClick: () =>
  //       handleImageClick(
  //         process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"
  //       ),
  //   },
  //   // {
  //   //     image: "/assests/ProductDetails/p1.png",
  //   //     onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
  //   // },
  //   // {
  //   //     image: "/assests/ProductDetails/p1.png",
  //   //     onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
  //   // },
  // ];
  // const [number, setNumber] = useState(1);
  // const handleMinusClick = () => {
  //   if (number > 1) {
  //     setNumber(number - 1);
  //   }
  // };

  // const handlePlusClick = () => {
  //   setNumber(number + 1);
  // };

  // const [showButtons, setShowButtons] = useState(false);
  // const [show, setShow] = useState(false);

  // const [heartactive, setheartActive] = useState(false);
  // const handleClickes = ({ data }) => {
  //   setheartActive(!heartactive);
  // };

  //   ----------------------------------------------------------------
  const {
    IMG_URL,
    htmlToReactParser,
    usertype,
    signin,
    setCart,
    setCartCount,
    wishlist,
    setWishlist,
  } = useContext(Context);

  const navigate = useNavigate();
  // swiper Ref
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstance2, setSwiperInstance2] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleMainSliderClick2 = (direction) => {
    if (swiperInstance2) {
      if (direction === "prev") {
        swiperInstance2.slidePrev();
      } else if (direction === "next") {
        swiperInstance2.slideNext();
      }
    }
  };

  // handle Image Click
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  // breadcrumb Items
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    {
      text: getValues("category.name") || "",
      link: "/b2bbulkorder",
      className: "active",
    },
  ]);

  useEffect(() => {
    setBreadcrumbItems([
      { text: "Home", link: "/" },
      {
        text: getValues("category.name"),
        link: "/",
        className: "active",
      },
    ]);
  }, [data]);
  // CheckPincodeAvailble

  const [deliveryChecked, setDeliveryChecked] = useState(false);
  const [serviceMessage, setServiceMessage] = useState("");
  const [pincodeData, setPincodeData] = useState({});

  const handleCheckDelivery = async (e) => {
    const val = {
      product_id: getValues("product_id"),
      // pincode: e.target.value,
      pincode: getValues("pincode"),
    };

    if (val?.pincode?.length < 6) {
      setError("pincode", {
        type: "manual",
        message: "Enter Complete Pincode"
      });
      setValue("available_pincode_status", false);
      return
    }
    const res = await postCheckPincodeAvailable(val);
    if (res?.success) {
      clearErrors();
      setDeliveryChecked(res.success);
      setServiceMessage(res?.message);
      setPincodeData(res?.data)
      setValue("available_pincode_status", true);
    } else {
      setError("pincode", {
        type: "manual",
        message: res?.message
      });
      setValue("available_pincode_status", false);
    }
    console.log("res?.message", res?.message);

  };

  console.log("pincodeData", pincodeData);


  // Add To Cart
  const handleAddToCart = async () => {
    if (getValues("available_pincode_status")) {
      const temp = {
        name: getValues("name"),
        short_description: getValues("short_description"),
        product_id: getValues("product_id"),
        image: IMG_URL + getValues("image"),
        // variant_id: data.product_quantities.find(() => true)?.id,
        p_a_v_id: getValues("p_a_v_id"),
        p_a_v_a_v_id: getValues("p_a_v_a_v_id"),
        mrp: getValues("mrp"),
        price:
          usertype == UserType.B2BCustomer
            ? getValues("btb_price")
            : getValues("btc_price"),
        quantity: getValues("min_quantity"),
        box_unit: getValues("box_unit"),
        type: getValues("product_type") == product_type.B2B ? "bulk" : "single",
        tax_percentage: getValues("tax_percentage"),
        status: 1,
        moq_id: getValues("moq_id"),
        moq: getValues("moq"),
        discount_percentage: getValues("discount_percentage"),
        coupon_status: getValues("coupon_status"),
        coupon_discount: getValues("coupon_discount"),
      };

      // console.log(temp);

      if (
        getValues("product_type") == product_type.B2B &&
        usertype == UserType.B2BCustomer
      ) {
        await AddToCart(temp);
        // setModalShow(true);
        updateCartCount(signin, setCart, setCartCount);
        navigate("/cart");
      } else if (
        getValues("product_type") == product_type.B2C ||
        getValues("product_type") == product_type.SampleRoll
      ) {
        await AddToCart(temp);
        // setModalShow(true);
        updateCartCount(signin, setCart, setCartCount);
        navigate("/cart");
      } else {
        setModalShow("Please Login As B2B Customer Before Add To Cart");
      }
    } else {
      setError("pincode", {
        type: "manual",
        message: "Please Check Available in Pincode",
      });
    }
  };

  const [modalShow, setModalShow] = useState(false);
  // Buy Now
  const handleBuyNow = async () => {
    if (getValues("available_pincode_status")) {
      const temp = {
        // name: getValues("name"),
        // short_description: getValues("short_description"),
        // product_id: getValues("product_id"),
        // image: getValues("image"),
        // mrp: getValues("mrp"),
        // // variant_id: data.product_quantities.find(() => true)?.id,
        // p_a_v_id: getValues("p_a_v_id"),
        // p_a_v_a_v_id: getValues("p_a_v_a_v_id"),
        // price:
        //   usertype == UserType.B2BCustomer
        //     ? getValues("btb_price")
        //     : getValues("btc_price"),
        // quantity: getValues("min_quantity"),
        // tax_percentage: getValues("tax_percentage"),
        // status: 1,

        name: getValues("name"),
        short_description: getValues("short_description"),
        product_id: getValues("product_id"),
        image: getValues("image"),
        // variant_id: data.product_quantities.find(() => true)?.id,
        p_a_v_id: getValues("p_a_v_id"),
        p_a_v_a_v_id: getValues("p_a_v_a_v_id"),
        price:
          usertype == UserType.B2BCustomer
            ? getValues("btb_price")
            : getValues("btc_price"),
        quantity: getValues("min_quantity"),
        box_unit: getValues("box_unit"),
        type: getValues("product_type") == product_type.B2B ? "bulk" : "single",
        tax_percentage: getValues("tax_percentage"),
        status: 1,
        moq_id: getValues("moq_id"),
        moq: getValues("moq"),
        mrp: getValues("mrp"),
        is_cod: getValues("is_cod"),
        is_returnable: getValues("is_returnable"),
        is_refundable: getValues("is_refundable"),
        is_cancellable: getValues("is_cancellable"),
        discount_percentage: getValues("discount_percentage"),
        coupon_status: getValues("coupon_status"),
        pincode: pincodeData?.pincode,
        delivery_charges: pincodeData?.rate,
        coupon_discount: getValues("coupon_discount")
          ? getValues("coupon_discount")
          : 0,
      };

      if (getValues("moq_id")) {
        const discount =
          (getValues("btb_price") / 100) * getValues("moq").percentage;
        const price = getValues("btb_price") - discount;
        temp.price = price;
      }
      // console.log(getValues("btb_price"));
      // console.log(getValues("btc_price"));

      // console.log(temp);
      if (signin) {
        if (
          getValues("product_type") == product_type.B2B &&
          usertype == UserType.B2BCustomer
        ) {
          await BuyNow(temp);
          navigate("/buy-now");
        } else if (
          getValues("product_type") == product_type.B2C ||
          getValues("product_type") == product_type.SampleRoll
        ) {
          await BuyNow(temp);
          navigate("/buy-now");
        } else {
          setModalShow("Please Login As B2B Customer Before Buy Now");
        }
      } else {
        // setModalShow(true);
        setModalShow("Please Login Before Buy Now");
      }
    } else {
      setError("pincode", {
        type: "manual",
        message: "Please Check Available in Pincode",
      });
    }
  };

  // Add Wish List
  const AddWishList = async () => {
    // setIsFavorite(!isFavorite);
    if (signin) {
      const data = {
        product_id: getValues("product_id"),
      };
      await addWishlist(data);
      await wishlistContext(signin, setWishlist);
    } else {
      // setModalShow(true);
      setModalShow("Please Login Before Add To Wish List Now");
    }
  };

  const [isMagnified, setIsMagnified] = useState(false);

  const toggleMagnify = () => {
    setIsMagnified(!isMagnified);
  };

  const [isVisible, setIsVisible] = useState(false);

  // Function to toggle visibility
  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  const dropdownRef = useRef(null);
  //
  // Detect click outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false); // Hide the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleNavigateAndScroll = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <section className="ProductSlider">
        <Container>
          <Row>
            <Col xxl={6} xl={6} lg={6}>
              <Breadcrumbs items={breadcrumbItems} />
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              {/* <div className="drop">
                <p className="text-p">Category</p>
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-p"
                  >
                    {selectedOption}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {visibleOptions.map((option) => (
                      <Dropdown.Item
                        eventKey={option.key}
                        key={option.key}
                        className="text-p"
                      >
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </Col>
          </Row>

          <Row className="main">
            <Col xxl={5} xl={5} lg={12}>
              <div className="Desktop-View">
                <div className="row camera-sec">
                  <Col xxl={2} xl={2} lg={2} md={2} className="p-0">
                    <div className="camera-slider">
                      <Swiper
                        slidesPerView={"auto"}
                        modules={[Autoplay]}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          370: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                          },
                          485: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                          },
                          575: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },

                          768: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                          },
                          992: {
                            slidesPerView: 6,
                            spaceBetween: 30,
                          },
                          1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },
                          1200: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },
                          1440: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                          },
                          2000: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                          },
                        }}
                        navigation={true}
                        pagination={{ clickable: true }}
                        direction={"vertical"}
                        className="mySwiper"
                      >
                        {images?.map((slide, index) => (
                          <SwiperSlide key={index}>
                            <div className="Main-div">
                              <div
                                className="camera-imgs"
                                onClick={() => handleImageClick(slide)}
                              >
                                {slide.type == "image" ? (
                                  <img
                                    src={IMG_URL + slide.image}
                                    className="inner-img"
                                  />
                                ) : (
                                  <div>
                                    {/* <video
                                      type="mp4"
                                      data={IMG_URL + slide.image}
                                      className="inner-img"
                                    /> */}

                                    <video className="prodselectvdo">
                                      <source
                                        src={IMG_URL + slide.image}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                )}

                                {/* <video className="inner-img" controls>
                                    <source
                                      src={IMG_URL + slide.image}
                                      type="video/mp4"
                                    />
                                  </video>
                                  
                                 */}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleMainSliderClick("prev")}
                        >
                          <FontAwesomeIcon icon={faAngleUp} />
                        </div>
                        <div
                          className="next-btn"
                          onClick={() => handleMainSliderClick("next")}
                        >
                          <FontAwesomeIcon icon={faAngleDown} />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xxl={10} xl={10} lg={6} md={8} className="">
                    <div className="card-sec ">
                      <div className="product-detail text-center">
                        {selectedImage ? (
                          <div>
                            {selectedImage.type == "image" ? (
                              <>
                                {/* <GlassMagnifier
                                  imageSrc={IMG_URL + selectedImage.image}
                                  imageAlt="Example"
                                  largeImageSrc={IMG_URL + selectedImage.image}
                                /> */}

                                {/* <Magnifier
                                  imageSrc={IMG_URL + selectedImage.image}
                                  imageAlt="Example"
                                  largeImageSrc={IMG_URL + selectedImage.image} // Optional
                                  mouseActivation={
                                    MOUSE_ACTIVATION.DOUBLE_CLICK
                                  } // Optional
                                  touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
                                /> */}

                                <ReactImageMagnify
                                  {...{
                                    smallImage: {
                                      alt: "Wristwatch by Ted Baker London",
                                      isFluidWidth: true,
                                      src: `${IMG_URL}${selectedImage.image}`,
                                      sizes:
                                        "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                    },
                                    largeImage: {
                                      alt: "",
                                      src: `${IMG_URL}${selectedImage.image}`,
                                      // width: 1200,
                                      // height: 1800,

                                      width: 900,
                                      height: 1500,
                                    },
                                    isHintEnabled: true,
                                    enlargedImageContainerStyle: {
                                      backgroundColor: "#fff",
                                      zIndex: 99999,
                                    },
                                  }}
                                />

                                {/* <img
                                  src={IMG_URL + selectedImage.image}
                                  onClick={toggleMagnify}
                                  className="zoomable__img"
                                  // className={isMagnified  ? 'magnified zoomable__img' : 'thumbnail zoomable__img'}
                                  alt="Selected"
                                /> */}
                              </>
                            ) : (
                              <object
                                data={IMG_URL + selectedImage.image}
                                className="zoomable__img"
                                alt="Selected"
                              />
                            )}
                          </div>
                        ) : (
                          <div className="specs">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assests/ProductDetails/p1.png"
                              }
                              className="zoomable__img"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </div>
              </div>

              {/* this slider for mobile view started*/}
              <div className="sidebar-swiper-mobile-view">
                <div className="row camera-sec">
                  <div className="card-sec ">
                    <div className="product-detail text-center">
                      {selectedImage ? (
                        <div>
                          {selectedImage.type == "image" ? (
                            <img
                              src={IMG_URL + selectedImage.image}
                              className="zoomable__img"
                              alt="Selected"
                            />
                          ) : (
                            <object
                              data={IMG_URL + selectedImage.image}
                              className="zoomable__img"
                              alt="Selected"
                            />
                          )}

                          {/* <img
                            src={selectedImage}
                            className="zoomable__img"
                            alt="Selected"
                          /> */}
                        </div>
                      ) : (
                        <div className="specs">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assests/ProductDetails/p1.png"
                            }
                            className="zoomable__img"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="camera-slider p-3">
                    <div className="row p-0 m-0">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Swiper
                          slidesPerView={"auto"}
                          modules={[Autoplay]}
                          onSwiper={(swiper) => setSwiperInstance2(swiper)}
                          breakpoints={{
                            0: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            370: {
                              slidesPerView: 3,
                              spaceBetween: 10,
                            },
                            485: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            575: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },

                            768: {
                              slidesPerView: 6,
                              spaceBetween: 20,
                            },
                            992: {
                              slidesPerView: 6,
                              spaceBetween: 30,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                            1200: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                            1440: {
                              slidesPerView: 5,
                              spaceBetween: 30,
                            },
                            2000: {
                              slidesPerView: 5,
                              spaceBetween: 20,
                            },
                          }}
                          navigation
                          pagination={{ clickable: true }}
                          className="mySwiper"
                        >
                          {images?.map((slide, index) => (
                            <SwiperSlide key={index}>
                              <div className="Main-div">
                                <div
                                  className="camera-imgs"
                                  onClick={() => handleImageClick(slide)}
                                >
                                  {slide.type == "image" ? (
                                    <img
                                      src={IMG_URL + slide.image}
                                      className="inner-img"
                                    />
                                  ) : (
                                    <>
                                      <video className="prodselectvdo">
                                        <source
                                          src={IMG_URL + slide.image}
                                          type="video/mp4"
                                          className="zoomable__img"
                                        />
                                      </video>

                                      {/* <object
                                      data={IMG_URL + slide.image}
                                      className="inner-img"
                                    /> */}
                                    </>
                                  )}

                                  {/*  <video className="inner-img" controls>
                                      <source
                                        src={IMG_URL + slide.image}
                                        type="video/mp4"
                                      />
                                    </video> 
                                    
                                    
                                    <object
                                      data={IMG_URL + slide.image}
                                      className="inner-img"
                                    /> */}

                                  {/* <img
                                    src={IMG_URL + slide.image}
                                    // src={process.env.PUBLIC_URL + slide.image}
                                    className="inner-img"
                                  /> */}
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <div className="silder-btn">
                          <div
                            className="back-btn"
                            onClick={() => handleMainSliderClick2("prev")}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </div>
                          <div
                            className="next-btn"
                            onClick={() => handleMainSliderClick2("next")}
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* this slider for mobile view End*/}
            </Col>

            <Col xxl={7} xl={7} lg={12}>
              <Row className="justify-content-center Total">
                <Col xxl={7} xl={7} lg={7}>
                  <div className="slider-description">
                    <h1 className="explore-btn">
                      {getValues("name")} {getValues("short_description")}
                    </h1>
                    <div className="border-main"></div>
                    <Row className="mt-3">
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p>
                          <span className="mainpriseee">
                            -
                            {(
                              ((getValues("mrp") -
                                (usertype == UserType.B2BCustomer
                                  ? getValues("btb_price")
                                  : getValues("btc_price"))) /
                                getValues("mrp")) *
                              100
                            ).toFixed(0)}
                            %
                          </span>{" "}
                          <span className="sale-price">
                            ₹
                            {usertype == UserType.B2BCustomer
                              ? getValues("btb_price")
                              : getValues("btc_price")}
                          </span>
                        </p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="main-price">
                          M.R.P :
                          <span className="main-price price-sale">
                            {" "}
                            ₹{getValues("mrp")}{" "}
                          </span>
                          <br></br>(Inclusive of all taxes)
                        </p>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Ratings:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <div className="main-div-ratinggg" ref={dropdownRef}>
                          <div className="d-flex">
                            <Rating value={average_rating} readOnly />
                            <p
                              className="arrow-class mb-1"
                              onClick={toggleDropdown}
                            >
                              <FontAwesomeIcon icon={faAngleDown} />
                            </p>
                          </div>
                          {isVisible && (
                            <div className="below-drop-down-div">
                              <div className="Content-Six-Tab">
                                <p className="commmontext">
                                  {customerRatingrData?.total_counts} Global
                                  Ratings
                                </p>
                                {customerRatingrData?.rating_counts?.map(
                                  (rating, index) => (
                                    <div
                                      className="rating-progressbar"
                                      key={index}
                                    >
                                      <p className="commmontext">
                                        {rating?.name}
                                      </p>
                                      <ProgressBar
                                        now={rating?.percentage}
                                        variant="secondary"
                                      />
                                      <p className="commmontext">
                                        {rating?.percentage}%
                                      </p>
                                    </div>
                                  )
                                )}
                                <div className="blk-border"></div>
                                <div className="text-center">
                                  <span
                                    className="customer-reviews-txttt"
                                    onClick={(e) =>
                                      handleNavigateAndScroll(
                                        e,
                                        "customer-reviews"
                                      )
                                    }
                                  >
                                    See Customer Reviews
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          <p className="rating-txtttt mb-1">{customerRatingrData?.total_counts}</p>
                        </div>
                      </Col>
                    </Row>

                    {/* {singleVariant && (
                      <Row>
                        <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                          <p className="text-p rate">
                            {getValues("variant")} :
                          </p>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                          <Form>
                            <div className="radio-btn-input-holder">
                              <div className="row">
                                {getValues("variant_details")?.map(
                                  (val, index) => (
                                    <div className="col-md-4" key={index}>
                                      <Form.Check
                                        type="radio"
                                        name="name"
                                        checked={
                                          getValues("active1") == val.id
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          // console.log(val.id);
                                          onChangeVariant(val, 1, 1);
                                        }}
                                        label={val?.sub_attribute?.name}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    )}

                    {MultipleVariant && (
                      <Row>
                        <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                          <p className="text-p rate">
                            {getValues("variant")} :
                          </p>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                          <Form>
                            <div className="radio-btn-input-holder">
                              <div className="row">
                                {getValues("variant_details")?.map(
                                  (val, index) => (
                                    <div className="col-md-4" key={index}>
                                      <Form.Check
                                        key={index}
                                        type="radio"
                                        name="name"
                                        checked={
                                          getValues("active1") == val.id
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setValue("active1", val?.id);

                                          setValue(
                                            "variant_one",
                                            val?.p_a_v_as[0]?.attribute?.name
                                          );
                                          setValue(
                                            "variant_one_details",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs
                                          );

                                          setValue(
                                            "mrp",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.mrp
                                          );

                                          setValue(
                                            "btb_price",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.btb_price
                                          );
                                          setValue(
                                            "btc_price",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.btc_price
                                          );
                                          setValue(
                                            "stock",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.stock
                                          );
                                          setValue(
                                            "p_a_v_id",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.p_a_v_id
                                          );
                                          setValue(
                                            "p_a_v_a_v_id",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.p_a_v_a_v_id
                                          );

                                          setValue(
                                            "product_id",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant?.product_id
                                          );

                                          setValue(
                                            "active2",
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]?.id
                                          );

                                          setImages(
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant.variant_images
                                          );
                                          setSelectedImage(
                                            val?.p_a_v_as[0]?.p_a_v_a_vs[0]
                                              ?.variant.variant_images[0]
                                          );
                                          // CalculateMOQ();
                                          reset(getValues());
                                        }}
                                        label={val?.sub_attribute?.name}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                          <p className="text-p rate">
                            {getValues("variant_one")} :
                          </p>
                        </Col>

                        <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                          <div className="radio-btn-input-holder">
                            <div className="row">
                              {getValues("variant_one_details")?.map(
                                (val, index) => (
                                  <div className="col-md-4" key={index}>
                                    <Form.Check
                                      key={index}
                                      type="radio"
                                      name="name"
                                      checked={
                                        getValues("active2") == val.id
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        // console.log(val.id);
                                        onChangeVariant(val, 2);
                                      }}
                                      label={val?.sub_attribute?.name}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )} */}

                    {/* <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Sizes:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <Form>
                          <div className="radio-btn-input-holder">
                            <Form.Check
                              type="radio"
                              name="name"
                              label="1 Inch"
                            />

                            <Form.Check
                              type="radio"
                              name="name"
                              label="2 Inch"
                            />
                            <Form.Check
                              type="radio"
                              name="name"
                              label="3 Inch"
                            />
                          </div>
                        </Form>
                      </Col>
                    </Row> */}

                    {/* <Row className="mt-3">
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Colour:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="main-price">
                          <Form className="color-main">
                            <div className="radio-btn-input-holder">
                              <Form.Check
                                type="radio"
                                name="name"
                                label="Transparent"
                                className="transparent"
                              />

                              <Form.Check
                                type="radio"
                                name="name"
                                label="Brown"
                                className="brown"
                              />
                            </div>
                          </Form>
                        </p>
                      </Col>
                    </Row> */}

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Brand:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("brand")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Color:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("color")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Size:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("width")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Meter:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("length")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Micron:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("thickness")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Per Box Tapes :</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("box_unit")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Material:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("matarial")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">Manufacturing:</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">{getValues("sold_by")}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate Coupontxt ">Coupon :</p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <div className="d-flex">
                          <div>
                            {/* <Form.Check
                              {...register("coupon_status")}
                              type="checkbox"
                              defaultChecked={getValues("coupon_status")} // Use defaultChecked for a checkbox
                              onChange={(e) => {
                                setValue("coupon_status", e.target.checked);
                                console.log(e.target.checked); // Log the checkbox's checked value
                              }}
                              label="Use Coupon"
                            /> */}

                            <input
                              {...register("coupon_status")}
                              type="checkbox"
                              defaultChecked={getValues("coupon_status")}
                              onChange={(e) => {
                                const b2b = Number(getValues("btb_price"));
                                const b2c = Number(getValues("btc_price"));
                                const coupon = getValues("product_cuppon");

                                let coupon_discount =
                                  getValues("coupon_discount") || 0;

                                if (e.target.checked) {
                                  if (
                                    coupon?.coupon_type ===
                                    product_coupon.Percentage
                                  ) {
                                    let discount = 0;
                                    if (usertype == UserType.B2BCustomer) {
                                      discount =
                                        (b2b / 100) * Number(coupon?.discount);
                                    } else {
                                      discount =
                                        (b2c / 100) * Number(coupon?.discount);
                                    }
                                    coupon_discount = discount;
                                  } else {
                                    coupon_discount = Number(
                                      coupon?.total_amount
                                    );
                                  }

                                  const b2bT = b2b - coupon_discount;
                                  const b2cT = b2c - coupon_discount;

                                  setValue("btb_price", b2bT.toFixed(2));
                                  setValue("btc_price", b2cT.toFixed(2));
                                } else {
                                  const b2bT = b2b + coupon_discount;
                                  const b2cT = b2c + coupon_discount;

                                  setValue("btb_price", b2bT.toFixed(2));
                                  setValue("btc_price", b2cT.toFixed(2));
                                }
                                setValue("coupon_discount", coupon_discount);
                                setValue("coupon_status", e.target.checked);

                                // console.log(coupon_discount);
                                // console.log(e.target.checked);

                                // console.log(getValues("product_cuppon"));
                                reset(getValues());
                              }}
                              className="form-check me-2"
                            />
                          </div>
                          <div>
                            {getValues("coupon_status") ? (
                              <p className="text-p-coupon-apply">
                                {" "}
                                {product_coupon.Percentage ===
                                  getValues("product_cuppon.coupon_type")
                                  ? " " +
                                  getValues("product_cuppon.discount") +
                                  "%"
                                  : "₹ " +
                                  getValues(
                                    "product_cuppon.total_amount"
                                  )}{" "}
                                Off Coupon Applied
                              </p>
                            ) : (
                              <p className="text-p-coupon-not-apply">
                                {" "}
                                Apply
                                {product_coupon.Percentage ===
                                  getValues("product_cuppon.coupon_type")
                                  ? " " +
                                  getValues("product_cuppon.discount") +
                                  "%"
                                  : " ₹ " +
                                  getValues(
                                    "product_cuppon.total_amount"
                                  )}{" "}
                                Coupon
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                        <p className="text-p rate">
                          Recommended Uses For Product:
                        </p>
                      </Col>
                      <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                        <p className="text-p">Packaging</p>
                      </Col>
                    </Row> */}

                    {getValues("product_type") == product_type.B2B && (
                      <div className="buymoresliderdiv ">
                        <div className="slideflexx">
                          <div className="titledivvv mt-1">
                            <p className="tettt">Buy more:</p>
                            <p className="tettt">Save more:</p>
                          </div>

                          <div className="sliderdivv">
                            <Swiper
                              spaceBetween={10}
                              centeredSlides={false}
                              slidesPerView={2.3}
                              pagination={{
                                clickable: true,
                              }}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              className="mySwiper"
                            >
                              {getValues("product_min_order_qties")?.map(
                                (item, index) => (
                                  <SwiperSlide key={index}>
                                    <div className="buymorbox">
                                      <p className="unitpp">
                                        {item?.quantity} Units
                                      </p>
                                      <p className="savemorep">
                                        <span>₹</span>
                                        {item?.price}
                                      </p>
                                      <p className="unitpp">
                                        Save <span>{item?.percentage}%</span>
                                      </p>

                                      <Form.Check
                                        type="radio"
                                        id={`moq-${item?.id}`}
                                        label=""
                                        value={item.id}
                                        checked={
                                          getValues("moq_id") == item?.id
                                        }
                                        onChange={() => {
                                          setValue("moq_id", item.id);
                                          setValue(
                                            "min_quantity",
                                            item.quantity
                                          );
                                          setValue("moq", item);
                                          reset(getValues());
                                        }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="Policy-Main mt-5">
                    {!deliveryChecked ? (
                      <Form>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Col sm="6">
                            <Form.Control
                              type="number"
                              placeholder="Enter Pincode"
                              name="pincode"
                              {...register("pincode", {
                                required: "Pincode required",
                              })}

                              className={classNames("", {
                                "is-invalid": errors?.pincode,
                              })}
                              onKeyDown={(e) => {
                                // Allow numbers, arrow keys, backspace, and prevent any input that exceeds 6 digits
                                const isNumber = /[0-9]/.test(e.key);
                                const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                                const isBackspace = e.key === 'Backspace';

                                if (!isNumber && !isArrowKey && !isBackspace || (e.target.value.length >= 6 && !isBackspace)) {
                                  e.preventDefault(); // Prevents input if not a number, arrow, or backspace, or exceeds 6 digits
                                }
                              }}
                            />
                            {errors.pincode && (
                              <div className="invalid-feedback">
                                {errors.pincode.message}
                              </div>
                            )}
                          </Col>
                          <Form.Label
                            column
                            sm="6"
                            className="check-text commmontext"
                            onClick={handleCheckDelivery}
                            style={{ cursor: "pointer" }}
                          >
                            Check Delivery
                          </Form.Label>
                        </Form.Group>
                      </Form>
                    ) : (
                      <Form>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Col sm="3">
                            {/* <Form.Control
                              type="text"
                              placeholder="Enter Pincode"
                              name="pincode"
                              {...register("pincode", {
                                required: "Pincode required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.pincode,
                              })}
                              onChange={(e) => {
                                setValue("Pincode", e.target.value);
                                setDeliveryChecked(false);
                              }}
                              onKeyDown={(e) => {
                                // Allow Backspace, Left Arrow, Right Arrow and numbers (0-9)
                                if (
                                  e.key === "Backspace" ||
                                  e.key === "ArrowLeft" ||
                                  e.key === "ArrowRight" ||
                                  (e.key >= "0" && e.key <= "9" && e.target.value.length < 6)
                                ) {
                                  return;
                                } else {
                                  e.preventDefault(); // Prevent any other key
                                }
                              }}
                              maxLength={6} // This will restrict the length to 6 digits
                            /> */}

                            <Form.Control
                              type="text"
                              name={`pincode`}
                              placeholder="Pincode"
                              className={classNames("", {
                                "is-invalid": errors?.contact_no,
                              })}

                              {...register(`pincode`, {
                                required: "Pincode is required",
                                pattern: {
                                  value: /^[0-9]{6}$/, // Regex to ensure only 6 digits are allowed
                                  message: "Pincode must be exactly 6 digits",
                                },
                              })}
                              onChange={(e) => {
                                setValue("Pincode", e.target.value);
                                setDeliveryChecked(false);
                              }}
                              onKeyDown={(e) => {
                                // Allow numbers, arrow keys, backspace, and prevent any input that exceeds 6 digits
                                const isNumber = /[0-9]/.test(e.key);
                                const isArrowKey = e.key === 'ArrowLeft' || e.key === 'ArrowRight';
                                const isBackspace = e.key === 'Backspace';

                                if (!isNumber && !isArrowKey && !isBackspace || (e.target.value.length >= 6 && !isBackspace)) {
                                  e.preventDefault(); // Prevents input if not a number, arrow, or backspace, or exceeds 6 digits
                                }
                              }}
                            />

                            {errors.pincode && (
                              <div className="invalid-feedback">
                                {errors.pincode.message}
                              </div>
                            )}
                          </Col>
                          <Form.Label column sm="9" className="check-text">
                            <ul>
                              <li className="service-dot">
                                {" "}
                                {serviceMessage &&
                                  <p className="service small-text">
                                    {serviceMessage}
                                  </p>
                                }
                              </li>
                            </ul>
                          </Form.Label>
                        </Form.Group>
                      </Form>
                    )}
                  </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={6}>
                  <div className="Payment-Collection">
                    <p className="price1">
                      {/* -
                      {(
                        ((getValues("mrp") -
                          (usertype == UserType.B2BCustomer
                            ? getValues("btb_price")
                            : getValues("btc_price"))) /
                          getValues("mrp")) *
                        100
                      ).toFixed(0)}
                      %  */}
                      ₹{" "}
                      {usertype == UserType.B2BCustomer
                        ? getValues("btb_price")
                        : getValues("btc_price")}
                    </p>
                    <p className="text-p">
                      <span className="free">FREE Delivery</span>{" "}
                      {calculateFutureDate(getValues("free_delivery_days"))}
                    </p>
                    {pincodeData?.fatestETD &&

                      <p className="text-p">
                        <span className="free">Or Fastest Delivery,</span>{" "}
                        {/* {calculateFutureDate(getValues("fast_delivery_days"))} */}
                        {pincodeData?.fatestETD}
                        <br></br>
                      </p>
                    }
                    <div className="sold">
                      <p className="text-p">Sold By: </p>
                      <p className="text-p">
                        <span className="free">{getValues("sold_by")}</span>
                      </p>
                    </div>
                    {getValues("product_type") == product_type.B2B ? (
                      <Form.Group>
                        <Form.Label>Quantity : </Form.Label>
                        <select
                          className="ms-2"
                          onChange={(e) => {
                            const selectedItem = getValues(
                              "product_min_order_qties"
                            ).find(
                              (item) => item.id === Number(e.target.value)
                            );
                            if (selectedItem) {
                              setValue("moq_id", selectedItem.id);
                              setValue("min_quantity", selectedItem.quantity);
                              setValue("moq", selectedItem);
                              reset(getValues());
                            }
                          }}
                        >
                          {getValues("product_min_order_qties")?.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                <>
                                  <div className="white-bggg">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <p className="save-txtttt">
                                          {item.quantity + " "}
                                          {/* + ₹{item?.price}  */}
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p className="save-txtttt">
                                          ( Save {item?.percentage}%)
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </option>
                            )
                          )}
                        </select>
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <Form.Label>Quantity : </Form.Label>
                        {/* <select
                          onChange={(e) => {
                            const selectedValue = Number(e.target.value); // Get the selected value
                          }}
                        >
                          {Array.from(
                            { length: 50 }, // Create an array of 100 items
                            (_, i) => getValues("minimum_quantity") + i * 2 // Generate values starting from minimum_quantity
                          ).map(
                            (value) =>
                              Number.isFinite(value) && (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              )
                          )}
                        </select> */}
                        <select
                          className="ms-2"
                          onChange={(e) => {
                            const selectedValue = Number(e.target.value); // Get the selected value
                            setValue("min_quantity", selectedValue);
                            reset(getValues());
                            // console.log(selectedValue);
                          }}
                        >
                          {Array.from(
                            { length: 10 },
                            (_, i) => getValues("minimum_quantity") + i // Generate values starting from minimum_quantity
                          ).map(
                            (value) =>
                              Number.isFinite(value) && (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              )
                          )}
                        </select>
                        {/* <div className=" d-flex">
                          Quntity:
                          <div>
                            <p
                              className="main-div ms-1"
                              onClick={() => setIsVisible(!isVisible)} 
                              style={{ cursor: "pointer" }} 
                            >
                              22 <FontAwesomeIcon icon={faChevronDown} />
                            </p>
                            {isVisible && (
                              <div className="below-div-class">
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                                <p>25</p>
                              </div>
                            )}
                          </div>
                        </div> */}
                      </Form.Group>
                    )}
                    <p className="text-p main">
                      Only {getValues("stock")} Left in stock
                    </p>
                    <p className="">
                      Minimum order quantity : {getValues("minimum_quantity")}
                    </p>
                    <div className="Button-Carts">
                      <div className="cart">
                        {/* <Link to={"/cart"}> */}
                        <Button_Common
                          className="cart"
                          text="Add to Cart"
                          onClick={handleAddToCart}
                          icon={faCartPlus}
                        />
                        {/* </Link> */}
                      </div>
                      <div className="cart mt-2">
                        {/* <Link to={"/buy-now"}> */}
                        <Button_Common
                          className="buy"
                          text="Buy Now"
                          onClick={handleBuyNow}
                          icon={faCartPlus}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="border-main"></div>
                    <div className="lock">
                      <FontAwesomeIcon icon={faLock} className="icon-lock" />
                      <p className="text-p">Secure transaction</p>
                    </div>
                    <div className="wishlist">
                      <Button_Common
                        className="wish"
                        onClick={AddWishList}
                        text={
                          wishlist?.some((item) => item.id === getValues("id"))
                            ? "Remove to Wish List"
                            : "Add to Wish List"
                        }
                      />
                    </div>
                  </div>

                  <div className="discountboxx">
                    <img
                      className="logooo"
                      src={
                        process.env.PUBLIC_URL + "/assests/logo/cmj-logo.png"
                      }
                    />
                    <p>
                      Save up to 15% on this product with business pricing and
                      GST input tax credit
                    </p>
                    <button
                      className="accntbtn"
                      onClick={() => navigate("/register")}
                    >
                      Create a free account
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xxl={5} xl={5} lg={5} md={5}></Col>
            <Col xxl={7} xl={7} lg={12} md={12}>
              <div className="Policy-Main mt-5">
                <Row>
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    // pagination={{ clickable: true, dynamicBullets: true }}
                    breakpoints={{
                      0: { slidesPerView: 1 },
                      768: { slidesPerView: 2 },
                      991: { slidesPerView: 2 },
                      1440: { slidesPerView: 3 },
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="myswiper"
                  >
                    {getValues("product_features")?.map((val, index) => (
                      <SwiperSlide key={index}>
                        <div className="policy">
                          <div className="image-holder">
                            <img
                              className="circle"
                              src={IMG_URL + val?.feature?.image}
                            />
                            {/* <FontAwesomeIcon icon={faHandHoldingHeart} /> */}
                          </div>
                          <p className="refund small-text">
                            {val?.feature?.name}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* {getValues("product_features")?.map((val, index) => (
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                      <div className="policy">
                        <div className="image-holder">
                          <img
                            className="circle"
                            src={IMG_URL + val?.feature?.image}
                          />
                        </div>
                        <p className="refund small-text">
                          {val?.feature?.name}
                        </p>
                      </div>
                    </Col>
                  ))} */}

                  {/* <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                    <div className="policy">
                      <div className="circle">
                        <FontAwesomeIcon icon={faHandHoldingHeart} />
                      </div>
                      <p className="refund small-text">Non Refundable</p>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                    <div className="policy">
                      <div className="circle">
                        <FontAwesomeIcon icon={faTruck} />
                      </div>
                      <p className="refund small-text">Fast Delivery</p>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                    <div className="policy">
                      <div className="circle">
                        <FontAwesomeIcon icon={faBox} />
                      </div>
                      <p className="refund small-text">Product saftey</p>
                    </div>
                  </Col> */}
                </Row>
                <div className="border-main"></div>
                <p className="about commmontext">About This item</p>

                {htmlToReactParser.parse(getValues("about")) || ""}

                {/* <ul>
                  <li className="sub-text">
                    CMJ Tape Made in India is Ideal for Packing E-Commerce
                    Boxes, Packing Cartons, Export Packaging, and Retail Packing
                    and can be used for Arts & Crafts work or for as Office
                    Stationery Product, Quality Micron adhesive tape, works best
                    for arts, stationary, protecting labels from water damage,
                    kid's school projects and other crafty activities
                  </li>
                  <li className="sub-text">
                    CMJ BOPP tapes being thermoplastic polymer works in both the
                    extreme temperatures that mean at low as well as
                    high-temperature ranges. The adhesives used commonly are hot
                    melt synthetic rubber as it seals quickly, reliable and
                    consistent. These adhesives bonds quickly to the surface
                    with additional properties like UV, shear and heat
                    resistant. The outstanding features which complement the
                    tapes are
                  </li>
                  <li className="sub-text">
                    CMJ Polypropylene film can be stretched in both the
                    directions thus mentioned as biaxial oriented. This
                    stretching of the film increases the strength and
                    clarity/transparency of the film. High tensile strength and
                    rugged nature making it ideal to use for packaging and
                    labeling.
                  </li>
                  <li className="sub-text">
                    CMJ Adhesive packing tapes that are used in sealing medium
                    to heavy-duty carton sealing, shipping, and inventory
                    management and in logistics industries are actually BOPP
                    tapes.
                  </li>
                  <li className="sub-text">
                    CMJ Polypropylene has several other properties like
                    resistant to abrasion, chemically reacting agents, burst and
                    moisture. The surface of the film is easy to print and coat,
                    which makes it useful for custom printed BOPP packing tapes.
                    The tape can be easily slitting when required
                  </li>
                </ul> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Warning_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={modalShow}
      // message={"Please Login Before Buy Now"}
      />
    </>
  );
};

export default ProductSlider;
